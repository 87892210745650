html {
  height: 100%;
  -webkit-overflow-scrolling: touch !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: transparent !important;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
